import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import EndrexLogo from '@shared/ui/display/EndrexLogo';
import { alignToCenter, ContainerColumn } from '@components/styled';
import BackLinkButton from '@shared/ui/buttons/BackLinkButton';
import ResetButton from '@shared/ui/buttons/ResetButton';
const Container = alignToCenter(ContainerColumn);
const FormContainer = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: 'inherit',
    height: 'inherit',
    flex: 1,
    paddingTop: 62,
}));
const NavContainer = styled(Container)(props => ({
    flex: 1,
    flexDirection: 'row',
    alignItems: 'start',
    padding: '0 100px',
    justifyContent: props.showNavigation ? 'space-between' : 'center',
    width: 'calc(100% - 200px)',
}));
const InnerContainer = styled(Container)(() => ({
    flex: 4,
}));
const Title = styled.h1(props => ({
    marginBottom: props.theme.spacing_sizes.xs * 5,
    userSelect: 'none',
    textAlign: 'center',
}));
const LinkContainer = styled.div(props => ({
    display: 'inline-flex',
    flex: 3,
    justifyContent: 'center',
    gap: `0 ${props.theme.spacing_sizes.xs * 1.25}px`,
    paddingTop: props.theme.spacing_sizes.xs * 15,
}));
const ButtonContainer = styled.div(() => ({
    // added to make buttons equal in width so the logo would be displayed at center
    minWidth: 84,
}));
const FormWrapper = ({ titleText = undefined, renderInnerForm = undefined, renderLinks = undefined, showNavigation = false, backLinkProps = undefined, handleReset = undefined, }) => (_jsxs(FormContainer, { children: [_jsxs(NavContainer, { showNavigation: showNavigation, children: [showNavigation ? (_jsx(ButtonContainer, { children: _jsx(BackLinkButton, { onClick: backLinkProps === null || backLinkProps === void 0 ? void 0 : backLinkProps.onClick, children: (backLinkProps === null || backLinkProps === void 0 ? void 0 : backLinkProps.text) || '' }) })) : null, _jsx(EndrexLogo, {}), showNavigation ?
                    _jsx(ButtonContainer, { children: _jsx(ResetButton, { onClick: handleReset }) }) :
                    null] }), _jsxs(InnerContainer, { children: [_jsx(Title, { children: titleText }), renderInnerForm || null] }), _jsx(LinkContainer, { children: renderLinks || null })] }));
export default FormWrapper;
